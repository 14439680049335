import React from 'react';

import { componentAllowedPerms } from 'services/permissions';
import { UserTypeGenerated } from 'services/api/autogenerated';
import Grid from '@mui/material/Grid';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import statesUSA from 'utils/statesUSA.json';
import companiesTypes from 'services/constants/allCompaniesTypes.json';

export default componentAllowedPerms(['companies:all'], () => <p>Access refused</p>)(
  function CompaniesTableToolbar({
    searchFilter,
    selectedState,
    setSelectedState,
    selectedTypes,
    setSelectedTypes,
    onSearchFilterChange,
    handleAdd,
    selectedRowsIds,
    handleClickOpen,
    isOpenDeletePopup,
    handleClose,
    handleDelete,
  }) {
    return (
      <Grid container spacing={2}>
        <Grid item sm={3} xs={12}>
          <TextField
            defaultValue={searchFilter}
            variant="outlined"
            id="companiesSearchFilter"
            onChange={onSearchFilterChange}
            fullWidth
            placeholder={'Search'}
            style={{
              fontSize: '12px',
              lineHeight: '10px',
              letterSpacing: '-0.05px',
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Autocomplete
            disabled={!statesUSA || statesUSA.length < 2}
            options={statesUSA || []}
            getOptionLabel={option => option.name || ''}
            value={selectedState}
            onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => {
              setSelectedState(value || {});
            }}
            renderInput={params => (
              <TextField
                {...params}
                label="State"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
              />
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            multiple
            options={companiesTypes}
            value={selectedTypes || []}
            getOptionLabel={option => option}
            onChange={(event, value) => setSelectedTypes(value as UserTypeGenerated[])}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                required
                label="Types"
                placeholder="Filter by types"
              />
            )}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={6} marginBottom={2}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleAdd}
              >
                <Typography variant="button">Add</Typography>
              </Button>
            </Grid>
            <Grid item xs={6} marginBottom={2}>
              {selectedRowsIds && (
                <>
                  <Button
                    fullWidth
                    disabled={!selectedRowsIds.length}
                    variant="outlined"
                    color="error"
                    onClick={handleClickOpen}
                  >
                    Delete
                  </Button>
                  <Dialog open={isOpenDeletePopup} onClose={handleClose}>
                    <DialogTitle>
                      Confirm delete {selectedRowsIds.length} {'companies'}
                    </DialogTitle>
                    <DialogContent>Are you sure?</DialogContent>
                    <DialogActions>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        width={'100%'}
                      >
                        <Button onClick={handleDelete} variant="contained" color="primary">
                          <Typography variant="button">Yes</Typography>
                        </Button>
                        <Button autoFocus onClick={handleClose} color="inherit" variant="text">
                          <Typography variant="button">Cancel</Typography>
                        </Button>
                      </Box>
                    </DialogActions>
                  </Dialog>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);
