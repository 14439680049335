import React from 'react';

import {
  componentAllowedPerms,
  useHasAccess,
  useHasPerms,
  usePermissionsContext,
} from 'services/permissions';
import Grid from '@mui/material/Grid';
import { Autocomplete, Link, TextField, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CompaniesApi from 'services/postgres/CompaniesApi';
import { UserTypes } from 'lg-helpers/dist/constants/user/UserTypes';

const companiesApi = new CompaniesApi();

export default componentAllowedPerms(['projects:read'], () => <p>Access refused</p>)(
  function ProjectsTableToolbar({
    searchFilter,
    regions,
    selectedRegion,
    setSelectedRegion,
    onSearchFilterChange,
    scales,
    selectedScales,
    setSelectedScales,
    archiveFilter,
    setArchiveFilter,
  }) {
    const navigate = useNavigate();
    const { user, currentRole } = usePermissionsContext();

    // Transporter admin can't create batches, but should be able to see them
    const batchedManifestButtonVisible =
      (useHasPerms(['batched_manifests:create']) || currentRole === 'transporter_admin') &&
      useHasAccess([
        async u => {
          if (u.role === 'super_admin' || u.role === 'customer_admin') return true;
          const companyRes = await companiesApi.getById(u.companies[0].id || '');
          return !!companyRes?.allowMultipleManifests;
        },
      ])?.hasAccess;

    const userCanAddProject = [...Object.values(UserTypes.admin)].some(
      type => user.actingAsType === type
    );

    const canFilterArchived = [
      ...Object.values(UserTypes.admin),
      ...Object.values(UserTypes.generator),
    ].some(type => user.actingAsType === type);

    const canFilterRegion = [...Object.values(UserTypes.admin)].some(
      type => user.actingAsType === type
    );

    const canFilterScale = [...Object.values(UserTypes.admin), UserTypes.scale.admin].some(
      type => user.actingAsType === type
    );

    return (
      <Grid container spacing={1} alignItems="center" marginBottom={2}>
        <Grid item sm={4} xs={12}>
          <TextField
            defaultValue={searchFilter}
            variant="outlined"
            id="companiesSearchFilter"
            onChange={onSearchFilterChange}
            fullWidth
            placeholder={'Search'}
            style={{
              fontSize: '12px',
              lineHeight: '10px',
              letterSpacing: '-0.05px',
            }}
          />
        </Grid>
        {canFilterRegion && (
          <Grid item sm={3} xs={12}>
            <Autocomplete
              disabled={!regions || regions.length < 2}
              options={regions || []}
              getOptionLabel={option => option.name || ''}
              value={selectedRegion}
              onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => {
                setSelectedRegion(value || {});
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.id || ''}>
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Region"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {canFilterScale && (
          <Grid item sm={5} xs={12}>
            <Autocomplete
              disabled={!scales || scales.length < 2}
              options={scales || []}
              getOptionLabel={option => option.name || ''}
              value={selectedScales}
              onChange={(event: React.SyntheticEvent<Element, Event>, value: any) => {
                setSelectedScales(value || {});
              }}
              multiple
              renderOption={(props, option) => (
                <li {...props} key={option.id || ''}>
                  {option.name}
                </li>
              )}
              renderInput={params => (
                <TextField
                  {...params}
                  label="Scale"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>,
                  }}
                />
              )}
            />
          </Grid>
        )}
        {userCanAddProject && (
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/super-admin/project')}
            >
              <Typography variant="button">Add</Typography>
            </Button>
          </Grid>
        )}
        {canFilterArchived && (
          <Grid item>
            <Button
              color="primary"
              variant={!archiveFilter ? 'outlined' : 'contained'}
              onClick={() => setArchiveFilter(!archiveFilter)}
            >
              <Typography variant="button">
                Show {!archiveFilter ? 'Archived' : 'Active'}
              </Typography>
            </Button>
          </Grid>
        )}
        <Grid item>
          <Link
            height="100%"
            component={Button}
            color="primary"
            onClick={() => navigate('/company/reports')}
            underline="none"
          >
            <Typography variant="button">
              {currentRole === 'scale_admin' ? 'Regional' : 'Company'} Report
            </Typography>
          </Link>
        </Grid>
        {batchedManifestButtonVisible && (
          <Grid item>
            <Link
              height="100%"
              component={Button}
              color="primary"
              onClick={() => navigate('/batched-manifests')}
              underline="none"
            >
              <Typography variant="button">Batched Manifests</Typography>
            </Link>
          </Grid>
        )}
      </Grid>
    );
  }
);
